import React from "react";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils";
import ScrollContainer from 'react-indiana-drag-scroll'

const Breadcrumbs_Insights =(props) => {

  let path_name = (props.location.pathname).split('/');
  // let get_url = path_name[path_name.length-1];
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
       return s.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
   return(
  <div className="breadcrumb-block breadcrumb-content text-center">   
        <Row> 
        <Col lg={12}>

          <ScrollContainer className="scroll-container">
            <Breadcrumb>
              <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
              <li className="breadcrumb-item"><Link to={'/'+path_name[1]}>About</Link></li>
              <li className="breadcrumb-item"><Link to={'/'+path_name[1]+'/'+path_name[2]}>{capitalize(path_name[2].replace(/-/g, ' '))}</Link></li>
              <li className="breadcrumb-item"  >{props.Heading}</li>
            </Breadcrumb>
          </ScrollContainer>
        </Col>
        </Row>
   
  </div>
  )
}

export default Breadcrumbs_Insights;

