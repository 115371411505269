import React from "react"
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs_MarketStatistics from "../components/Breadcrumbs/Breadcrumbs_market-statistics"
import ArticleBlock from "../components/ArticleBlock/ArticleBlock"
import NewsLetter from "../components/NewsLetter/NewsLetter"
import FilterBlock from "../components/FilterBlock/FilterBlock"
import AuthorBlock from "../components/Insights/AuthorBlock";
//import {InsightsDetails} from "../queries/common_use_query"
//import LoaderLogo from '../images/loader_logo_new.svg';
import "../scss/custom.scss"
import { removeDataAttributes } from "../comQueryStructure";

const MarketStatistics = (props) => {

  var mypagedata = removeDataAttributes(props?.data?.glstrapi?.marketStatistics);

  var data = mypagedata[0]


  return (
    <>
      <Layout Layout={'Without_Banner'} classNames="insight_det Without_Banner" Pagename={props.GQLPage?.Pagename}>
        <SEO title={data && data.Meta_Title} description={data && data.Meta_Description} image={data?.Tile_Image?.url?data?.Tile_Image?.url:data?.Banner_Image?.url} />

        <Helmet />

        {
          data && <>
            <div className="page-content static-page">
              <Breadcrumbs_MarketStatistics page_name={data.Title} Heading={data.Title} location={props.location} />
              <ArticleBlock article_data={data} />             
            </div>
          </>
        }
      </Layout>
    </>
  )
}

export const query = graphql`
query insightQuery ($url: String){
  glstrapi {
    marketStatistics(filters: {URL: {eq: $url}}) {     
        data {
        attributes {
          Title
          Contents
          URL
          Title_images {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          embedded_url          
        }
        id
      }    
    }
  }
}`

export default MarketStatistics